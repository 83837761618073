
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import GButton from '@/components/gsk-components/GskButton.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import { getApproval, sendApproval } from '@/api/registration.api';
import { Approval } from '@/types/registration.types';
import { TextField } from '@/components/form/form.types';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { openErrorSnackbar } from '@/utils/components';
import { ApprovalType } from '@/types/notifications.types';

@Component({
  components: {
    GButton,
    MaxWidth,
    DeleteDialog,
    ValidatedFormDialog,
    Promised,
  },
})
export default class ApprovalView extends Vue {
  denyOpen = false;
  approval: Promise<Approval> | null = null;
  responseLoading = false;
  denialModel: [TextField] = [
    {
      type: 'long-text',
      label: 'Denial Reason',
      key: 'denial',
      value: '',
      required: true,
      validation: {
        rules: 'required',
      },
    },
  ];
  deny(data: Approval) {
    this.responseLoading = true;
    this.doApproval(
      data.approvalRequestId,
      this.denialModel[0].value,
      EnumsModule.enums.status.REJECTED.id,
      EnumsModule.enums.status.REJECTED.mnemonic,
      data.approvalRequestTypeMnemonic,
    );
  }
  approve(data: Approval) {
    this.doApproval(
      data.approvalRequestId,
      'Approved',
      EnumsModule.enums.status.APPROVED.id,
      EnumsModule.enums.status.APPROVED.mnemonic,
      data.approvalRequestTypeMnemonic,
    );
  }

  doApproval(
    approvalRequestId: string | number,
    approvalMessage: string,
    updateStatusId: number,
    updateStatusMnemonic: string,
    approvalRequestTypeMnemonic: string,
  ) {
    this.responseLoading = true;
    sendApproval(
      approvalRequestId,
      {
        approvalMessage,
        updateStatusId,
        updateStatusMnemonic,
      },
      approvalRequestTypeMnemonic as ApprovalType,
    )
      .then(() => true)
      .catch((err: AxiosError) => {
        openErrorSnackbar.call(this, err.response?.data?.message ?? 'Server Error');
      })
      .then(success => {
        if (success) {
          this.load();
        }
      })
      .finally(() => {
        this.responseLoading = false;
        this.denyOpen = false;
      });
  }

  status(approval: Approval) {
    const id = approval.statusId;
    const s = EnumsModule.enums.status;
    const isPending = id === s.PENDINGAPPROVAL.id;
    return {
      isApproved: id === s.APPROVED.id,
      isRejected: id === s.REJECTED.id,
      isPending,
      statusName: approval.statusName,
    };
  }
  load() {
    this.approval = getApproval(this.$route.params.approvalId)
      .then(r => r.data)
      .catch((e: AxiosError) => {
        // format and re-throw error so vue-promise catches
        if (e && e.response) {
          throw e.response.data.message;
        }
        throw e;
      });
  }
  created() {
    this.load();
  }
}
