
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { FormField, ServerErrors } from '@/components/form/form.types';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import { runCustomValidators } from '@/components/form/utils';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    GDialog,
    GButton,
    ValidatedForm,
    ValidationObserver,
  },
  inheritAttrs: false,
})
export default class ValidatedFormDialog extends Vue {
  // required .sync props: open
  @Prop({ required: true, type: Boolean }) readonly open!: boolean;
  @Prop({ type: Boolean }) readonly loading!: boolean;
  @Prop({ type: Boolean }) readonly danger!: boolean;
  @Prop({ type: Array, required: true })
  value!: FormField[];
  @Prop({ type: String, default: 'Submit' }) readonly buttonText!: string;
  @Prop({ type: String, default: 'Cancel' }) readonly cancelText!: string;
  @Prop({ type: Object, default: () => ({}) }) readonly serverErrors!: ServerErrors;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  validating = true;

  mounted() {
    // next tick didn't work here
    // and there's no clear way to know that
    // all of the validation layers are rendered and ready
    setTimeout(async () => {
      this.$refs.observer?.validate({ silent: true }).then(() => {
        if (Object.keys(this.serverErrors).length) {
          this.setServerErrors(this.serverErrors);
        }
        this.validating = false;
      });
    });
  }

  @Watch('serverErrors', { deep: true })
  handleServerErrors(errors: ServerErrors) {
    setTimeout(() => {
      this.setServerErrors(errors);
    });
  }

  @Watch('value', { deep: true })
  reValidate() {
    this.validate();
  }

  setServerErrors(errors: ServerErrors) {
    this.$refs.observer?.setErrors(errors);
  }

  validate() {
    return this.$refs.observer.validate().then(async (isValid: boolean) => {
      if (isValid) {
        const res = await runCustomValidators(this.value);
        if (res.hasErr) {
          this.validating = false;
          // the components have a weird issue with their error state
          // and disabled props, the errors need to be set some time after
          // the disabled prop is toggled
          setTimeout(() => {
            this.setServerErrors(res.serverErrors);
          });
          return false;
        }
      }
      return isValid;
    });
  }

  reset() {
    this.$refs.observer.reset();
  }

  get form() {
    return this.value;
  }

  set form(v) {
    this.$emit('input', v);
  }

  @Emit('update:open')
  update(isOpen: boolean): boolean {
    return isOpen;
  }
  @Emit('submit')
  submit(value: FormField[]): FormField[] {
    return value;
  }

  cancel() {
    this.update(false);
    this.$emit('cancel');
  }

  handleSubmit() {
    this.validating = true;
    this.validate()
      .then(isValid => {
        if (isValid) {
          this.submit(this.value);
        }
      })
      .finally(() => {
        this.validating = false;
      });
  }
}
