
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MaxWidth extends Vue {
  @Prop(Boolean) readonly isForm!: boolean;
  @Prop(Boolean) readonly fullScreen!: boolean;
  @Prop({ type: String, default: '' }) readonly contentPadding!: string;
  @Prop({ type: String, default: '' }) readonly contentBackgroundColor!: string;
  @Prop({ type: Object, default: () => ({}) }) readonly contentStyle!: Record<
    string,
    string | number
  >;

  @Prop({ type: String, default: '' }) readonly containerBackgroundColor!: string;
  @Prop({ type: Object, default: () => ({}) }) readonly containerStyle!: Record<
    string,
    string | number
  >;

  get containerStyles() {
    const out = { ...this.containerStyle };
    if (this.containerBackgroundColor) {
      out.backgroundColor = this.containerBackgroundColor;
    }
    return out;
  }
  get contentStyles() {
    const out = { ...this.contentStyle };
    if (this.contentBackgroundColor) {
      out.backgroundColor = this.contentBackgroundColor;
    }
    if (this.contentPadding) {
      out.padding = this.contentPadding;
    }
    return out;
  }
}
